import React, { useEffect, useRef, useState } from "react";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { Card } from "react-bootstrap";
import { KTCard } from "../../_metronic/helpers";
import MuiTable from "../../components/table/MuiTable";
import { GridColDef } from "@mui/x-data-grid";
import { Box, Tooltip } from "@mui/material";
import { deleteeBatch, getBatchList } from "../../api";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useMutation } from "react-query";
import { PageTitle } from "../../_metronic/layout/core";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import useDebounce from "../../hooks/useDebounce";
import AddEditBatch from "./AddEditBatch";

const Batch: React.FC = () => {
    const tableRef = useRef();

    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [tableOptions, setTableOptions] = useState({
        offset: 0,
        limit: 10,
        hasNextPage: true,
        hasPrevPage: true,
        totalRowCount: undefined,
        search: "",
    });
    const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

    const handleCloseDelete = () => {
        setShowDeleteConfirmModal(false);
    };

    const { mutateAsync: deleteBatchAPI, isLoading: isDeleting } = useMutation("delete-batch", deleteeBatch);

    const {
        mutateAsync: getBatchListAPI,
        data: batchData,
        isLoading: isLoading,
    } = useMutation(getBatchList);

    const getBatch = async (query: any) => {
        let response = await getBatchListAPI(query);
        if (response?.status) {
            setTableOptions((prevState: any) => {
                return {
                    ...prevState,
                    offset: response?.data?.offset,
                    limit: response?.data?.limit,
                    hasNextPage: response?.data?.hasNextPage,
                    hasPrevPage: response?.data?.hasPrevPage,
                    totalRowCount: response?.data?.totalDocs,
                };
            });
        }
    };

    const deleteBatch = async () => {
        let response = await deleteBatchAPI({ id: selectedRow?._id });
        if (response?.status) {
            snackActions.success(response?.message);
            handleCloseDelete();
            getBatch({ offset: 0, limit: 10 });
        } else {
            snackActions.error(response?.message);
        }
    };

    const handleModalClose = (isSubmit: boolean) => {
        setShowAddEditModal(false);
        setSelectedRow("");
        if (isSubmit) {
            getBatch({ offset: 0, limit: 10 });
        }
    };

    useEffect(() => {
        getBatch({ offset: 0, limit: 10 });
    }, []);


    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            sortable: false,
            minWidth: 200,
            renderCell: (data: any) => data?.row?.name ?? "N/A",
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            sortable: false,
            headerAlign: "center",
            align: "center",
            renderCell: (data) => (
                <Box display="flex" alignItems="center">
                    <Tooltip title="Edit">
                        <DriveFileRenameOutlineIcon
                            onClick={() => {
                                setSelectedRow(data.row);
                                setShowAddEditModal(true);
                            }}
                            sx={{ fontSize: 22, cursor: "pointer" }}
                            color="primary"
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteSweepIcon
                            onClick={() => {
                                setSelectedRow(data.row);
                                setShowDeleteConfirmModal(true);
                            }}
                            sx={{ fontSize: 22, ml: 1.7, cursor: "pointer" }}
                            color="error"
                        />
                    </Tooltip>
                </Box>
            ),
        },
    ];

    const handlePagination = (values: { page: number; pageSize: number }) => {
        let page = values?.page;
        let pageSize = values?.pageSize;
        let query: any = {
            offset: pageSize === tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
            limit: pageSize ?? 10,
            search: debounceSearchValue ?? "",
        };
        getBatch(query);
    };

    return (
        <Card className="p-6">
            <>
                <PageTitle>Batch</PageTitle>
                <KTCard>
                    <>
                        <MuiTable
                            addLabel="Add Batch"
                            handleAddClick={() => { setShowAddEditModal(true); setSelectedRow("") }}
                            columns={columns}
                            data={batchData?.data?.docs ?? []}
                            loading={isLoading}
                            isRowClickable={true}
                            tableOptions={tableOptions}
                            handlePagination={handlePagination}
                            tableRef={tableRef}
                        />
                    </>
                </KTCard>
                {showAddEditModal && (
                    <AddEditBatch
                        editData={selectedRow}
                        show={showAddEditModal}
                        handleClose={handleModalClose}
                    />
                )}
                {showDeleteConfirmModal && (
                    <ConfirmEventPopup
                        showPopup={showDeleteConfirmModal}
                        handleClose={handleCloseDelete}
                        handleConfirmed={deleteBatch}
                        loading={isDeleting}
                        title={"Delete Batch"}
                        message={"Are you sure, want to delete this batch?"}
                    />
                )}
            </>
        </Card>
    );
};

export default Batch;
