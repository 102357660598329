import { APP_URLs, config } from "../constants/appURLs";
import { snackActions } from "../helpers/SnackUtilsConfigurator";
import httpClient from "./httpClient";

const getCourses = async () => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.course.getCourse,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getBatchList = async () => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.batch.getBatch,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getCoursesById = async (payload: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.course.getCourse}?collegeId=${payload}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getAssignmentDetails = async (payload: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.Assignment.genericAssignment}?assignmentByCourseID=${payload}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getCoursesPage = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.course.getCourse}?offset=${query?.offset}&limit=${query?.limit}&collegeId=${query?.collegeFilter ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const forgotPassword = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.User.forgotPassword,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const changePassword = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.User.changePassword,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addCourse = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.course.getCourse,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addBatch = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.batch.getBatch,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteCourse = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.course.deleteCourse}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const deleteeBatch = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.batch.deleteBatch}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getColleges = async () => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.college.getCollege,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getUserList = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.User.getAllUser}?roleId=2&offset=${query?.offset}&limit=10000&`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getUserDetailsById = async (payload: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.User.getUserDetailsById}/${payload?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getExcelTemplate = async () => {
  try {
    const { data } = await httpClient.get(`${APP_URLs.User.excelTemplate}`, config);
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const userBulkUpload = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.User.bulkUpload,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getCollegesPage = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.college.getCollege}?offset=${query?.offset}&limit=${query?.limit}&`,
      config,
    );
   return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const suspendActiveAdmin = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.User.suspendAdmin,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const addCollege = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.college.getCollege,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const sendBulkEmails = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.User.sendEmail,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteCollege = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.college.deleteCollege}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};


const getUsers = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.User.getAllUser}?roleId=2&offset=${query?.offset ?? 0}&limit=${query?.limit ?? 1000}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
}

const getStudents = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.User.getAllUser}?roleId=2&offset=${query?.offset}&limit=${query?.limit}&`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getAdminUsers = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.User.getAllUser}?roleId=1&offset=${query?.offset}&limit=${query?.limit}&`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getManager = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.User.getAllUser}?roleId=3&offset=${query?.offset}&limit=${query?.limit}&`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getCollegeAdmin = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.User.getAllUser}?roleId=4&offset=${query?.offset}&limit=${query?.limit}&`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};


const addUsers = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.User.addUser,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const editUsers = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.User.editUser,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getEvidence = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.Evidence.genericEvidence}?offset=${query?.offset}&limit=${query?.limit}&collegeId=${query?.collegeFilter ?? ""}&courseId=${query?.courseFilter ?? ""}&assignmentId=${query?.assignmentFilter ?? ""}&studentId=${query?.studentFilter ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const getEvidenceBytudentId = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.Evidence.getEvidenceByStudentId}/${query}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addEvidence = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.Evidence.genericEvidence,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const createZip = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.media.downloadEvidenceDocuments,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const approveRejectFunc = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.Evidence.approveRejectEvidence,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const approveEvidence = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.Evidence.approveEvidence,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getEvidenceById = async (id: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.Evidence.getEvidenceIndividual}/${id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteEvidence = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.Evidence.genericEvidence}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteUser = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.User.deleteUser,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }

};

const getAssignment = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.Assignment.genericAssignment}?offset=${query?.offset}&limit=${query?.limit}&collegeId=${query?.collegeFilter ?? ""}&courseId=${query?.courseFilter ?? ""}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};
const addAssignment = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.Assignment.genericAssignment,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const deleteAssignment = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.Assignment.genericAssignment}/${payloadData?.id}`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const documentUpload = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.mediaDocumentUpload,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const contactUs = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.ContactUs.contactUs,
      payloadData,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

const getSupport = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.ContactUs.contactUs}?offset=${query?.offset}&limit=${query?.limit}&`,
      config,
    );
    return data;
  } catch (error: any) {
    snackActions.dismissibleError(error?.message ?? "Something went wrong");
  }
};

export { getCourses,createZip, sendBulkEmails, getCollegeAdmin,deleteeBatch, getBatchList,addBatch, approveRejectFunc, getSupport, contactUs, getUserDetailsById, forgotPassword, changePassword, deleteUser, getManager, getStudents, userBulkUpload, getExcelTemplate, getCoursesById, getUserList, suspendActiveAdmin, getCoursesPage, getColleges, getCollegesPage, deleteEvidence, getAdminUsers, getUsers, getEvidence, addEvidence, deleteAssignment, getAssignment, addCourse, addCollege, addAssignment, deleteCollege, deleteCourse, addUsers, editUsers, documentUpload , getAssignmentDetails , approveEvidence , getEvidenceById , getEvidenceBytudentId};
