import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { KTIcon } from "../../_metronic/helpers";
import { addBatch } from "../../api";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: {
    _id?: string | undefined;
    name: string;
    desc: string;
    collegeId: {
      _id: string;
    };
  };
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const AddEditBatch = ({ show, handleClose, editData }: Props) => {
  const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: editData?.name ?? "",
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      let bodyParams = {
        _id: editData ? editData?._id : undefined,
        name: values?.name ?? "",
      };
      let response = await addAPI(bodyParams);
      if (response) {
        snackActions.success(response?.message ?? "Added/Edited Successfully!");
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.message ?? "Something went wrong!");
      }
    },
  });

  const {
    mutateAsync: addAPI,
    isLoading: isAdding,
    error: addError,
  } = useMutation("add-course", addBatch);


  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px scroll"
      show={show}
      onHide={() => handleClose(false)}
      backdrop={true}
    >
      <div className="modal-header">
        <h2>{editData ? "Edit" : "Add"} Batch</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div>
            <div className="fv-row mb-8">
              <label className="form-label fw-bolder text-dark fs-6">
                Batch Name
              </label>
              <input
                placeholder="Enter Batch name"
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("name")}
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              />
              {formik.touched.name && formik.errors.name && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>
          <div className="w-100 d-flex p-1 justify-content-end">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAdding ? "Loading.." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default AddEditBatch;
