import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APP_URLs, config } from "../../../constants/appURLs";
import { ILoginUserPayload } from "../../../constants/types";
import { snackActions } from "../../../helpers/SnackUtilsConfigurator";

export const userLogin: any = createAsyncThunk(
  "auth/login",
  async ({ email, password }: ILoginUserPayload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        APP_URLs.authEndPoints.login,
        { email, password },
        config,
      );
      if (data?.data?.token) {
        localStorage.setItem("userToken", data?.data?.token);
        localStorage.setItem("userType", data?.data?.role.join(', '));
        localStorage.setItem("userInfo", JSON.stringify(data?.data));
        const userInfoJSON: any = localStorage.getItem("userInfo");
        const userInfo: any = JSON.parse(userInfoJSON);
        if (userInfo?.isUserFirstLogin) {
          localStorage.setItem("isUserFirstLogin", "true")
        }
        snackActions.success(data.message ?? "LoggedIn successfully");
        setTimeout(() => {
          window.location.reload();
        });
      } else {
        snackActions.dismissibleError(data.message ?? "Something went wrong!");
      }
    } catch (error: any) {
      snackActions.dismissibleError(
        "The username or password doesn't match our records",
      );
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
// export const userLogout: any = createAsyncThunk(
//   "auth/logout",
//   async (_, { rejectWithValue }) => {
//     try {
//       const { data } = await axios.put(
//         APP_URLs.authEndPoints.logout,
//         { token: userToken },
//         config,
//       );
//       if (data?.status) {
//         localStorage.clear();
//         snackActions.success(data.message ?? "LoggedOut Successfully");
//         window.location.reload();
//       } else {
//         snackActions.dismissibleError(data.message ?? "Something went wrong!");
//       }
//     } catch (error: any) {
//       if (error.response && error.response.data.message) {
//         return rejectWithValue(error.response.data.message);
//       } else {
//         return rejectWithValue(error.message);
//       }
//     }
//   },
// );
