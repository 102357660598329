const BASE_URL = process.env.REACT_APP_BASE_URL;

export const userToken = localStorage.getItem("userToken");

// configure header's Content-Type as JSON
export const config = {
  headers: {
    ContentType: "application/json",
    accept: "application/json",
    Authorization: `Bearer ${userToken}`,
  },
};

export const APP_URLs = {
  mediaDocumentUpload: `${BASE_URL}/media/uploaddocument`,
  genericUserAPI: `${BASE_URL}/user/get-user-by-role-id`,
  mediaUpload: `${BASE_URL}/media/upload`,
  authEndPoints: {
    login: `${BASE_URL}/admin/signin`,
  },
  commonEndPoints: {
    getCountries: `${BASE_URL}/location/countries/?Skip=0&Take=1000`,
  },
  course: {
    getCourse: `${BASE_URL}/course`,
    deleteCourse: `${BASE_URL}/course`
  },
  college: {
    getCollege: `${BASE_URL}/college`,
    deleteCollege: `${BASE_URL}/college`
  },
  batch: {
    getBatch: `${BASE_URL}/batch`,
    deleteBatch: `${BASE_URL}/batch`
  },
  media: {
    downloadEvidenceDocuments : `${BASE_URL}/media/create-zip`,
  },
  User: {
    getAllUser :  `${BASE_URL}/admin/userList`,
    sendEmail :  `${BASE_URL}/admin/send-email`,
    addUser: `${BASE_URL}/admin/create-user`,
    editUser: `${BASE_URL}/admin/edit-details`,
    deleteUser: `${BASE_URL}/admin/delete-user`,
    suspendAdmin: `${BASE_URL}/admin/suspend-user`,
    forgotPassword: `${BASE_URL}/auth/forgot-password`,
    changePassword: `${BASE_URL}/user/change-password`,
    excelTemplate: `${BASE_URL}/admin/template-bulk-user`, 
    bulkUpload: `${BASE_URL}/admin/bulk-upload-user`,
    getUserDetailsById: `${BASE_URL}/admin/user`
  },  
  Evidence : {
    genericEvidence :  `${BASE_URL}/evidence`,
    getEvidenceIndividual :  `${BASE_URL}/evidence/getEvidenceById`,
    getEvidenceByStudentId :  `${BASE_URL}/evidence/getEvidencByStudentId`,
    approveRejectEvidence :  `${BASE_URL}/evidence/status`,
    approveEvidence :  `${BASE_URL}/evidence/verify-evidence`,
  },
  Assignment : {
    genericAssignment :  `${BASE_URL}/assignment`,
  },
  ContactUs : {
    contactUs :  `${BASE_URL}/contact-us`,
  }
};
