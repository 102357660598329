import clsx from "clsx";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { changePassword } from "../../api";
import { useMutation } from "react-query";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { KTCard } from "../../_metronic/helpers";
import { Box, Typography } from "@mui/material";
import { useState } from "react";

const initialValues = {
    oldPassword: "",
    newPassword: "",
    againNewPassword: "",
};

const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Please enter old password"),
    newPassword: Yup.string()
        .required("Please enter new password")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#?]).{6,}$/,
            'Password must be at least 6 characters and include a mixture of uppercase and lowercase letters, numbers, and at least one special character (!@#?)'
        ),
    againNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Passwords must match')
        .required('Please confirm your new password'),
});

export function NewPassword() {
    const navigate = useNavigate();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showAgainNewPassword, setShowAgainNewPassword] = useState(false);
    const { mutateAsync: changePasswordAPI, isLoading: isLoading } = useMutation(changePassword);

    const formik = useFormik({
        initialValues,
        validationSchema: changePasswordSchema,
        onSubmit: async (values) => {
            let response = await changePasswordAPI({
                oldPassword: values?.oldPassword ?? '',
                newPassword: values?.newPassword ?? ''
            });
            if (response?.status) {
                snackActions.success(response?.message ?? "Success");
                setTimeout(() => {
                    navigate('/dashboard');
                }, 1000)
            }
        },
    });

    return (
        <KTCard>
            <Box width={0.5} justifyContent={"center"} margin={"auto"} padding={"3rem"}>
                <form
                    className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                    noValidate
                    id="kt_login_password_reset_form"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="text-center mb-10">
                        {/* begin::Title */}
                        <h1 className="text-dark fw-bolder mb-3">Change Password</h1>
                        {/* end::Title */}

                        {/* begin::Link */}
                        {/* <div className="text-gray-500 fw-semibold fs-6">
                            Change your password.
                        </div> */}
                        {/* end::Link */}
                    </div>

                    {/* end::Title */}

                    {/* begin::Form group */}
                    <Box
                        sx={{
                            border: '1px solid #228b22',
                            padding: '4px',
                            borderRadius: '4px',
                            backgroundColor: '#E8F5E9',
                            textAlign: "left",
                            mt: 2,
                            mb: 2,
                            li: {
                                mt: 0.5,
                            }
                        }}
                    >
                        <Typography variant="subtitle1" gutterBottom sx={{ color: '#006400', ml: 1 }}>
                            Password Rules:
                        </Typography>
                        <ul>
                            <li>At least 6 characters.</li>
                            <li>A mixture of both uppercase and lowercase letters.</li>
                            <li>A mixture of letters and numbers.</li>
                            <li>Include at least one special character (e.g., ! @ # ?)</li>
                        </ul>
                    </Box>
                    <div className="fv-row mb-4">
                        <label className="form-label fw-bolder text-gray-900 fs-6">Enter old password</label>
                        <div className="input-group">
                            <input
                                placeholder="Old password"
                                {...formik.getFieldProps("oldPassword")}
                                type={showOldPassword ? 'text' : 'password'}
                                className={clsx(
                                    "form-control bg-transparent",
                                    { "is-invalid": formik.touched.oldPassword && formik.errors.oldPassword },
                                    { "is-valid": formik.touched.oldPassword && !formik.errors.oldPassword },
                                )}
                                name="oldPassword"
                                autoComplete="off"
                            />
                            <span className="input-group-text" onClick={() => setShowOldPassword(!showOldPassword)}>
                                {showOldPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                            </span>
                        </div>
                        {formik.touched.oldPassword && formik.errors.oldPassword && (
                            <div className="fv-plugins-message-container">
                                <span role="alert">{formik.errors.oldPassword}</span>
                            </div>
                        )}
                    </div>
                    <div className="fv-row mb-4">
                        <label className="form-label fw-bolder text-gray-900 fs-6">New password</label>
                        <div className="input-group">
                        <input
                            placeholder="New password"
                            {...formik.getFieldProps("newPassword")}
                            className={clsx(
                                "form-control bg-transparent",
                                { "is-invalid": formik.touched.newPassword && formik.errors.newPassword },
                                {
                                    "is-valid": formik.touched.newPassword && !formik.errors.newPassword,
                                },
                            )}
                            type={showNewPassword ? 'text' : 'password'}
                            name="newPassword"
                            autoComplete="off"
                        />
                        <span className="input-group-text" onClick={() => setShowNewPassword(!showNewPassword)}>
                            {showNewPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                        </span>
                        </div>
                        {formik.touched.newPassword && formik.errors.newPassword && (
                            <div className="fv-plugins-message-container">
                                <span role="alert">{formik.errors.newPassword}</span>
                            </div>
                        )}
                    </div>
                    <div className="fv-row mb-8">
                        <label className="form-label fw-bolder text-gray-900 fs-6">Re-enter new password</label>
                        <div className="input-group">
                        <input
                            placeholder="Re-enter new password"
                            {...formik.getFieldProps("againNewPassword")}
                            className={clsx(
                                "form-control bg-transparent",
                                { "is-invalid": formik.touched.againNewPassword && formik.errors.againNewPassword },
                                {
                                    "is-valid": formik.touched.againNewPassword && !formik.errors.againNewPassword,
                                },
                            )}
                            type={showAgainNewPassword ? 'text' : 'password'}
                            name="againNewPassword"
                            autoComplete="off"
                        />
                        <span className="input-group-text" onClick={() => setShowAgainNewPassword(!showAgainNewPassword)}>
                            {showAgainNewPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                        </span>
                        </div>
                        {formik.touched.againNewPassword && formik.errors.againNewPassword && (
                            <div className="fv-plugins-message-container">
                                <span role="alert">{formik.errors.againNewPassword}</span>
                            </div>
                        )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className="d-flex flex-wrap pb-lg-0" style={{ justifyContent: "end" }}>
                        <button
                            type="submit"
                            id="kt_password_reset_submit"
                            className="btn btn-primary me-4"
                        >
                            <span className="indicator-label">
                                {isLoading ? "Loading..." : "Submit"}
                            </span>
                        </button>
                        <Link to="/dashboard">
                            <button
                                type="button"
                                id="kt_login_password_reset_form_cancel_button"
                                className="btn btn-light"
                                disabled={formik.isSubmitting || !formik.isValid}
                            >
                                Cancel
                            </button>
                        </Link>{" "}
                    </div>
                    {/* end::Form group */}
                </form>
            </Box>
        </KTCard>
    );
}
