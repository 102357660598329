import React from "react";
import { Card } from "react-bootstrap";
import { KTCard } from "../../_metronic/helpers";
// import { getMostSearchedLocation, getUsers } from "../../api";

const Dashboard: React.FC = () => {
  return (
    <Card className="p-6">
      <>
        <KTCard>
          {/* <h3 style={{ position: "absolute", top: "10px", left: "10px" }}> */}
            Dashboard
          {/* </h3> */}
        </KTCard>
      </>
    </Card>
  );
};

export default Dashboard;
