
import JSZip from 'jszip';
import download from 'downloadjs';

const charValidate = (text: string, limit: number) => {
  if (text && typeof text !== "undefined") {
    if (text.length > limit) {
      return `${text.substring(0, limit - 3)}...`;
    } else {
      return `${text.substring(0, limit)}`;
    }
  } else {
    return "";
  }
};

export { charValidate };


export const handleZipDownload = (documents: string[]) => {
  const zip = new JSZip();
  documents.forEach((document, index) => {
      const filename = `evidence_${index + 1}`;
      zip.file(filename, document);
  });

  zip.generateAsync({ type: "blob" })
      .then((blob: Blob) => {
          download(blob, "evidence.zip", "application/zip");
      })
      .catch((error: Error) => {
          console.error("Error creating zip file:", error);
      });
};