import { ErrorMessage, useFormik } from "formik";
import { useState, useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { KTIcon } from "../../_metronic/helpers";
import { addUsers, editUsers, getCourses, getColleges, getUsers } from "../../api";
import { useMutation, useQuery } from "react-query";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import { Checkbox, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { MultiSelect } from "react-multi-select-component";

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  editData: {
    _id?: string | undefined;
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    countryCode: string;
    role: string[];
    countryDialCode: any;
    collegeCourse: any,
    courseId: {
      _id: string;
    };
    collegeId: {
      _id: string;
    };
    manageColleges?: any[]; // Add this line
    manageCourses?: any[]; // Add this line
    manageStudents?: { _id: string; firstName: string; lastName: string }[];

  };
};

interface ISelectCourse {
  label: string;
  value: string;
}

interface ISelectCollege {
  label: string;
  value: string;
}

interface ISelectedUsers {
  label: string;
  value: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const removeDuplicates = (array: any[]) => {
  const seen = new Set();
  return array.filter((item: any) => {
    const serializedItem = JSON.stringify(item);
    if (seen.has(serializedItem)) {
      return false;
    }
    seen.add(serializedItem);
    return true;
  });
};

const modalsRoot = document.getElementById("root-modals") || document.body;
const AddEditManager = ({ show, handleClose, editData }: Props) => {
  // let courseIdValue: any = editData?.collegeCourse?.[0]?.courseId?._id;
  let courseIdValue: any[] = editData?.manageCourses?.map((courseID: any) => {
    // You can do any transformation here if necessary
    return courseID; // Return the same value or modify it if needed
}) || [];
  // Now, courseIds will contain the array of _id values or an empty array if any of the data is missing.
  let collegeIdValue: any[] = editData?.manageColleges?.map((collegeID: any) => {
    // You can do any transformation here if necessary
    return collegeID; // Return the same value or modify it if needed
}) || [];
  // let collegeIdValue: any = editData?.collegeCourse?.[0]?.collegeId?._id;
  const profileDetailsSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().required("Email is required"),
    mobileNumber: Yup.string().min(10).required("Mobile is required"),
    countryCode: Yup.string().required("Country Code is required"),
    // collegeId: Yup.array().required(),
    // courseId: Yup.array().required(),
  });
  const [countryDialCode, setCountryDialCode] = useState("");
  const [selectCourses, setSelectCourses] = useState<ISelectCourse[] | []>([]);
  const [selectColleges, setSelectColleges] = useState<ISelectCollege[] | []>([]);
  const [selectedUsers, setSelectedUsers] = useState<ISelectedUsers[] | []>([]);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [courseDataBasedOnCollege, setCourseDataBasedOnCollege] = useState([]);

  const formik = useFormik({
    initialValues: {
      firstName: editData?.firstName ?? "",
      lastName: editData?.lastName ?? "",
      email: editData?.email ?? "",
      mobileNumber: editData ? `${editData?.countryCode}${editData?.mobileNumber}` ?? "" : "",
      courseId: courseIdValue ?? [],
      collegeId: collegeIdValue ?? "",
      countryCode: editData?.countryCode ?? "",
    },
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      const  isAnyDropdownValueSelected = selectCourses?.length > 0 || selectedUsers?.length > 0;

      if(!isAnyDropdownValueSelected){
        setErrorMessage("Please select atleast course or users")
      }
      else{
        setErrorMessage(" ")
      }
      // let collegeCourse: any = [];
      // selectColleges.forEach((college) => {
      //   selectCourses.forEach((course) => {
      //     selectedUsers.forEach((user) => {
      //       collegeCourse.push({
      //         collegeId: college.value,
      //         courseId: course.value,
      //         studentId: user.value   // Adding studentId from selected users
      //       });
      //     });
      //   });
      // });

      // console.log(selectCourses,'selectCourses')
      // console.log(selectColleges,'selectColleges')
      // console.log(collegeCourse,'collegeCourse')
      let phoneNumber = values?.mobileNumber;
      let countryCodeValue = values?.countryCode ?? "";
      let trimmedPhoneNumber = phoneNumber.replace(`${countryCodeValue}`, "");
      let bodyParams = {
        userId: editData ? editData?._id : undefined,
        firstName: values?.firstName ?? "",
        lastName: values?.lastName ?? "",
        roleId: 3,
        mobileNumber: trimmedPhoneNumber ?? "",
        countryCode: countryCodeValue ? countryCodeValue : "+1",
        email: values?.email ?? "",
        // collegeCourse: collegeCourse
        manageColleges: selectColleges.map(item => item.value),
        manageCourses: selectCourses.map(item => item.value),
        manageStudents: selectedUsers.map(item => item.value)
      };
      if (isAnyDropdownValueSelected) {
        try {
          const response = editData
            ? await editAPI(bodyParams)
            : await addAPI(bodyParams);
    
          if (response) {
            snackActions.success(response?.message ?? "Added/Edited Successfully!");
            formik.resetForm();
            handleClose(true);
          } else {
            snackActions.error("Something went wrong!");
          }
        } catch (error) {
          snackActions.error("An error occurred!");
        }
      }
    },
  });

  const {
    mutateAsync: addAPI,
    isLoading: isAdding,
    error: addError,
  } = useMutation("add-users", addUsers);

  const {
    data: usersData,
  } = useQuery("user-list", getUsers);

  const {
    mutateAsync: editAPI,
    isLoading: isEditing,
    error: editError,
  } = useMutation("edit-user", editUsers);

  const {
    data: courseData
  } = useQuery("courses", getCourses);

  useEffect(() => {
if(selectColleges){
  const selectedCollegeIds = selectColleges.map((id:any) => id?.value)
  const dropdownDataForCourse = courseData?.data?.docs?.filter((collegeid: any) => selectedCollegeIds.includes(collegeid?.collegeId?._id))
  setCourseDataBasedOnCollege(dropdownDataForCourse)
}
  }, [selectColleges])
  const courseDataValues = courseData?.data?.docs;


  const {
    data: collegeData
  } = useQuery("colleges", getColleges);

  const collegeDataValues = collegeData?.data?.docs;

  const handleCourseChange = (value: any) => {
    setSelectCourses(value)
    setErrorMessage("")
  }


  const handleUserChange = (value: any) => {
    setSelectedUsers(value)
    setErrorMessage("")
  }

  // const [collegeName, setCollegeName] = useState<string[]>([]);
  // const [courseName, setCourseName] = useState<string[]>([]);

  // const handleCollegeChange = (event: SelectChangeEvent<typeof collegeName>) => {
  //   formik.handleChange(event)
  //   const { target: { value } } = event;
  //   setCollegeName(typeof value === 'string' ? value.split(',') : value);
  // };

  // const handleCourseChange = (event: SelectChangeEvent<typeof courseName>) => {
  //   formik.handleChange(event)
  //   const { target: { value } } = event;
  //   // console.log(value, ';value')
  //   setCourseName(typeof value === 'string' ? value.split(',') : value);
  // };
  useEffect(() => {
    if (editData) {
      // Handle Courses Selection
      if (courseDataValues?.length) {
        let selectedCourseList: any = [];
        editData?.manageCourses?.forEach((editCourse: any) => {
          courseDataValues.forEach((course: any) => {
            if (editCourse === course?._id) {
              selectedCourseList.push({ label: course?.name, value: course?._id });
            }
          });
        });
        selectedCourseList = removeDuplicates(selectedCourseList);
        setSelectCourses(selectedCourseList);
      }
  
      // Handle Colleges Selection
      if (collegeDataValues?.length) {
        let selectedCollegeList: any = [];
        editData?.manageColleges?.forEach((editCollege: any) => {
          collegeDataValues.forEach((college: any) => {
            if (editCollege === college?._id) {
              selectedCollegeList.push({ label: college?.name, value: college?._id });
            }
          });
        });
        selectedCollegeList = removeDuplicates(selectedCollegeList);
        setSelectColleges(selectedCollegeList);
      }
  
      // Handle Users Selection - Add optional chaining for manageStudents
      if (editData?.manageStudents && usersData?.data?.docs?.length) {
        let selectedUsersList: any = [];
        editData?.manageStudents?.forEach((editUser: any) => {
          usersData.data.docs.forEach((user: any) => {
            if (editUser === user?._id) {
              selectedUsersList.push({
                label: `${user?.firstName} ${user?.lastName}`,
                value: user?._id,
              });
            }
          });
        });
        selectedUsersList = removeDuplicates(selectedUsersList);
        setSelectedUsers(selectedUsersList);
      }
    }
  }, [editData, courseDataValues, collegeDataValues, usersData]);
  
  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-600px scroll"
      show={show}
      onHide={() => handleClose(false)}
      backdrop={true}
    >
      <div className="modal-header">
        <h2>{editData ? "Edit" : "Add"} Manager</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => handleClose(false)}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="p-6">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div>
            <div className="fv-row mb-8">
              <label className="form-label fw-bolder text-dark fs-6">
                First Name
              </label>
              <input
                placeholder="First name"
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("firstName")}
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.firstName}</div>
                </div>
              )}
            </div>
            <div className="fv-row mb-8">
              <label className="form-label fw-bolder text-dark fs-6">
                Last Name
              </label>
              <input
                placeholder="Last name"
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("lastName")}
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.lastName}</div>
                </div>
              )}
            </div>
            <div className="fv-row mb-8">
              <label className="form-label fw-bolder text-dark fs-6">
                Email
              </label>
              <input
                placeholder="Email"
                type="email"
                autoComplete="off"
                // disabled={editData ? true : false}
                {...formik.getFieldProps("email")}
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              // title={editData ? "This field is disabled" : undefined}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              )}
            </div>
            <div className="fv-row mb-8">
              <label className="form-label fw-bold">Mobile Number</label>
              <PhoneInput
                country={"us"}
                value={formik.values.mobileNumber}
                onChange={(value: string, country: any) => {
                  setCountryDialCode(country?.dialCode)
                  formik.setFieldValue("countryCode", country?.dialCode || "");
                  formik.setFieldValue("mobileNumber", value || "");
                }}
                inputProps={{
                  name: "mobileNumber",
                }}
                inputStyle={{
                  background: "#f9f9f9",
                  border: "none",
                }}
                inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 w-100"
              />
              {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <p style={{ color: "#F24C75" }}>{formik.errors.mobileNumber}</p>
                  </div>
                </div>
              )}
            </div>

            <div>
              {/* <label className="form-label fw-bolder text-dark fs-6">
                College
              </label>
              <select
                placeholder="Select College"
                {...formik.getFieldProps("collegeId")}
                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              >
                <option value="">Select College</option>
                {collegeDataValues?.map((college: any) => (
                  <option key={college?._id} value={college?._id}>
                    {college?.name}
                  </option>
                ))}
              </select> */}
              <InputLabel id="demo-multiple-checkbox-label" className="form-label fw-bold" style={{
                fontWeight: 'bold',
                color: '#2e3233',
                fontSize: '1.125rem'
              }}>Select College</InputLabel>
              <MultiSelect
                options={
                  collegeDataValues
                    ? collegeDataValues?.map((d: any) => {
                      return { label: d?.name, value: d?._id };
                    })
                    : []
                }
                value={selectColleges}
                onChange={setSelectColleges}
                labelledBy="Select"
                className="form-control form-control-lg form-control-solid p-1 mb-4"
              />
              {/* {isForEditProfile
                    ? null
                    : formik.touched.manageGolfs &&
                      !selectCourses?.length && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            Golf selection is required
                          </div>
                        </div>
                      )} */}
              {/* {formik.touched.collegeId && formik.errors.collegeId && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <p style={{ color: "#F24C75" }}>College Name is required</p>
                  </div>
                </div>
              )} */}
            </div>
            <div className="fv-row mb-8">
              <InputLabel id="demo-multiple-checkbox-label" className="form-label fw-bold" style={{
                fontWeight: 'bold',
                color: '#2e3233',
                fontSize: '1.125rem'
              }}>Select Course</InputLabel>
              <MultiSelect
                options={
                  courseDataBasedOnCollege
                    ? courseDataBasedOnCollege?.map((d: any) => {
                      return { label: d?.name, value: d?._id };
                    })
                    : []
                }
                value={selectCourses}
                onChange={(value: any) =>handleCourseChange(value)}
                labelledBy="Select"
                className="form-control form-control-lg form-control-solid p-1 mb-2"
              />
              {/* {isForEditProfile
                    ? null
                    : formik.touched.manageGolfs &&
                      !selectCourses?.length && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            Golf selection is required
                          </div>
                        </div>
                      )} */}
              {/* {formik.touched.courseId && formik.errors.courseId && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <p style={{ color: "#F24C75" }}>Course Name is required</p>
                  </div>
                </div>
              )} */}
            </div>
            <div className="fv-row mb-8">
              <InputLabel id="demo-multiple-checkbox-label" className="form-label fw-bold" style={{
                fontWeight: 'bold',
                color: '#2e3233',
                fontSize: '1.125rem'
              }}>Select Users</InputLabel>
              <MultiSelect
                options={
                  usersData
                    ? usersData?.data?.docs.map((d: any) => {
                      return { label: `${d?.firstName}${" "}${d?.lastName}`, value: d?._id };
                    })
                    : []
                }
                value={selectedUsers}
                onChange={(value: any) =>handleUserChange(value)}
                labelledBy="Select"
                className="form-control form-control-lg form-control-solid p-1 mb-2"
              />
              {/* {isForEditProfile
                    ? null
                    : formik.touched.manageGolfs &&
                      !selectCourses?.length && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            Golf selection is required
                          </div>
                        </div>
                      )} */}
              {/* {formik.touched.courseId && formik.errors.courseId && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <p style={{ color: "#F24C75" }}>Course Name is required</p>
                  </div>
                </div>
              )} */}
            </div>
            <div>
            <label style={{ color: "#fa4f4f", fontSize: "1rem", fontWeight: "400" }}>
                {errorMessage}
              </label>
            </div>
          </div>
          <div className="w-100 d-flex p-1 justify-content-end">
            <Button
              onClick={() => {
                formik.resetForm();
                handleClose(false);
              }}
              size="lg"
              variant="secondary"
            >
              Cancel
            </Button>
            <div className="w-15px"></div>
            <Button type="submit" size="lg" variant="primary">
              {isAdding || isEditing ? "Loading.." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot,
  );
};

export default AddEditManager;
